<template>
  <AdminToolbar v-if="$auth.adminAuthenticated" />
  <template v-if="$auth.authenticated">
    <template v-if="$breakpoints.lg.value">
      <AppHeader />
      <NavigationMobile />
      <NavigationMobileProfile />
    </template>
    <div class="w-full mx-auto relative lg:flex max-w-screen-2xl">
      <NavigationDesktop v-if="!$breakpoints.lg.value" />
      <div
        class="grow min-w-0 bg-white z-0 shadow-sm"
        data-testid="router-view"
      >
        <slot />
      </div>
    </div>
  </template>
</template>
