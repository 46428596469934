<template>
  <button
    class="support-button nav-link router-link"
    @click="$app.modal.open(SupportModal)"
  >
    <div class="icon-wrapper p-1">
      <SvgIcon
        name="help"
        class="w-5 h-5"
      />
    </div>
    Customer Support
  </button>
</template>

<script setup lang="ts">
const SupportModal = () => import('@/components/shared/SupportModal.vue')
</script>
