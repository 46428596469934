<template>
  <div class="nav-container">
    <nav class="nav-desktop">
      <OatsLogo to="/" />
      <hr class="w-full">
      <div class="nav-links">
        <NavigationLinks />
        <NavigationProfile />
      </div>
      <hr class="w-full mt-auto">
      <NavigationDesktopProfile />
    </nav>
  </div>
</template>
