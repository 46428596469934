<template>
  <nav
    v-if="customer"
    class="nav-links"
  >
    <RouterLink
      to="/account"
      class="flex gap-2 items-center"
    >
      <ProfilePicture class="w-10 h-10" />
      <div class="flex flex-col overflow-hidden">
        <div class="tracking-tight font-semibold">
          {{ customer.firstName }} {{ customer.lastName }}
        </div>
        <div class="text-xs text-neutral-400 truncate">
          {{ customer.email }}
        </div>
      </div>
    </RouterLink>
  </nav>
</template>

<script setup lang="ts">
defineEmits([ 'navigate' ])
const auth = useAuthStore()
const customer = computed(() => auth.shopify.customer)
</script>
