<template>
  <header class="app-header shadow-sm z-40">
    <div class="max-w-screen-lg mx-auto">
      <button
        class="w-16 h-16 outline-cyan-700"
        aria-label="open navigation"
        data-gtm-action="app header - open navigation"
        @click="$app.navVisible = true"
      >
        <SvgIcon
          name="hamburger"
          class="w-6 h-6"
        />
      </button>
      <OatsLogo to="/" />
      <button
        class="cursor-pointer p-4 "
        @click="$app.navProfileVisible = true"
      >
        <ProfilePicture class="h-10 w-10" />
      </button>
    </div>
  </header>
</template>
