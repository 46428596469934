<template>
  <div class="bg-red-600 text-white text-sm text-center p-1 flex justify-center gap-5 shadow-sm">
    <div class="flex flex-col items-start md:flex-row md:gap-4">
      <div v-if="shopify.customer">
        Shopify:
        <a
          :href="shopifyCustomerUrl(shopify.customer.id)"
          target="_blank"
          class="font-bold"
        >
          {{ shopify.customer.email }} |
          {{ shopify.customer.nid }}
        </a>
      </div>
      <div v-if="recharge.customer">
        Recharge:
        <a
          :href="rechargeCustomerUrl(recharge.customer.id)"
          target="_blank"
          class="font-bold"
        >
          {{ recharge.customer.email }} |
          {{ recharge.customer.id }}
        </a>
      </div>
    </div>
    <a
      href="/admin/customer"
      class="font-bold"
      @click.prevent="$auth.logout"
    >
      Exit
    </a>
  </div>
</template>

<script setup lang="ts">
const shopify = shopifyStores.useCustomerStore()
const recharge = rechargeStores.useCustomerStore()
</script>
