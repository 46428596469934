<template>
  <NavigationLink
    v-for="nav in navigation"
    :key="nav.path"
    :nav="nav"
    @click="$emit('navigate')"
  />
  <SupportButton @click="$emit('navigate')" />
</template>

<script setup lang="ts">
import type { NavigationLinkType } from '@/types'
const navigation: NavigationLinkType[] = [
  {
    label: 'Purchases',
    children: [
      {
        label: 'Subscriptions',
        path: '/subscriptions',
        icon: 'nav-subscriptions',
        recharge: true
      },
      {
        label: 'Order History',
        path: '/orders',
        icon: 'nav-order-history'
      }
    ]
  },
  {
    label: 'Account & Support',
    children: [
      {
        label: 'My Account',
        path: '/account',
        icon: 'nav-settings'
      }
    ]
  }
]
defineEmits([ 'navigate' ])
</script>
